import React from 'react';
import Layout from "../components/layout";
import SEO from "../components/seo";
import singlecbg from "../assets/images/single-c-bg.png"
import androidbn from "../assets/images/android-bn.png"
//import ad from "../assets/images/ad.png"
//import iPhone from "../assets/images/iPhone.png";
import HelpBG from "../assets/images/Help-BG.png";
import { graphql } from "gatsby"

const Solution = (props) => {
    return(
      props.data && 
      props.data.allWpTeamlanceSolution &&
      props.data.allWpTeamlanceSolution.edges[0] &&
      props.data.allWpTeamlanceSolution.edges[0].node &&
      props.data.allWpTeamlanceSolution.edges[0].node.TeamlanceSolutionSingle &&
      <Layout>
      <SEO title="Solution" />
      <div className="col-12 wrapper main-bggrey p-0">
      {/*inner pages content*/}
      <div className="col-12 inner-page-content home-inner px-0"> 
        {/*banner section*/}
        <section className=" single-case-banner android-banner">
          <div className="bn-bg mobile-hidden">
            <img src={singlecbg} alt="Android" />
          </div>
          <div className="bn-bg desktop-hidden">
            <img src={androidbn} alt="Android" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-6  d-flex flex-column sectiontop-pd ">
                <div className="banner-text case-heading service-banner my-auto ml-0 ">
                  <h3>{props.data.allWpTeamlanceSolution.edges[0].node.TeamlanceSolutionSingle.mainHeader.title}</h3>
                  <p>{props.data.allWpTeamlanceSolution.edges[0].node.TeamlanceSolutionSingle.mainHeader.description}</p>
                  <div className="mobile-center">  
                  <button onclick="location.href = 'calculator-step-1.html'; return false;" className="estimate-btn btn btn-bg btn-design text-white">
                      Estimate Your Project
                    </button></div>
                </div>
              </div>
              <div className="col-md-6 py-5  android-image">
                <div className="d-flex  pt-5 justify-content-center align-items-center ">
                  <img src={props.data.allWpTeamlanceSolution.edges[0].node.TeamlanceSolutionSingle.mainHeader.image.sourceUrl} alt="case study" />
                </div>
              </div>
            </div> 
          </div>
        </section> {/*banner section end*/}
      </div>
      {/*inner pages content*/}
    </div>
    <section className="about-android">
      <div className="bn-triangle">
        <svg width={385} height={185} viewBox="0 0 385 185" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M70.8221 167.583L-16.3348 87.733L-21 34.2605L377.287 64.1673L102.153 173.347C91.4676 177.588 79.2986 175.349 70.8221 167.583Z" fill="#D8F4FF" />
        </svg>
      </div>
      <div className="container">
        <div style={{position: 'relative', zIndex: 9}} className="row about-heading mb-5">
          <div className="col-md-7">
            <p>{props.data.allWpTeamlanceSolution.edges[0].node.TeamlanceSolutionSingle.makingProcess.title}</p>
            <h1>{props.data.allWpTeamlanceSolution.edges[0].node.TeamlanceSolutionSingle.makingProcess.subHeading}</h1>
          </div>
          <div className="col-md-5 d-flex align-items-center">
            <p className="about-disp mb-0">{props.data.allWpTeamlanceSolution.edges[0].node.TeamlanceSolutionSingle.makingProcess.description}</p>
          </div>
        </div>
        <div className="row ex">
          {props.data.allWpTeamlanceSolution.edges[0].node.TeamlanceSolutionSingle.makingProcess.processSteps
           &&props.data.allWpTeamlanceSolution.edges[0].node.TeamlanceSolutionSingle.makingProcess.processSteps.length>0
           &&props.data.allWpTeamlanceSolution.edges[0].node.TeamlanceSolutionSingle.makingProcess.processSteps.map((data,key)=>{
               return(
                <div className="col-md-3 Ad-tm my-4">
                  <div className="ad-count">{key+1}</div>
                  <h4>{data.title}</h4>
                  <p>{data.description}</p>
                  {key !== props.data.allWpTeamlanceSolution.edges[0].node.TeamlanceSolutionSingle.makingProcess.processSteps.length-1 && <div className="ad-arrow">
                    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2.5 8H13.5" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M9 3.5L13.5 8L9 12.5" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>}
                </div>
               )
           })}
          
        </div>
      </div>
    </section>
    <section className="help-sec custom-app-sec">
      <div className="aps-side">
        <svg width={172} height={340} viewBox="0 0 172 340" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M30.0216 108.888L529.841 -273.223V-232.413V263.397C531.261 350.702 529.841 541.758 529.841 613.226L30.0216 231.115C11.1567 216.496 0 193.96 0 170.002C0.202849 146.043 11.1567 123.507 30.0216 108.888Z" fill="#D8F4FF" />
        </svg>
      </div>
      <div className="help-back">
        <img className="mobile-hidden" src={HelpBG} />
        <img className="desktop-hidden" src={HelpBG} />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-7 custom-apps">
            <p className="blue-text">{props.data.allWpTeamlanceSolution.edges[0].node.TeamlanceSolutionSingle.customApps.title}</p>
            <h1 className="mb-4">
               {props.data.allWpTeamlanceSolution.edges[0].node.TeamlanceSolutionSingle.customApps.subHeading}
            </h1>
            <p dangerouslySetInnerHTML={{__html:props.data.allWpTeamlanceSolution.edges[0].node.TeamlanceSolutionSingle.customApps.description}}></p>
          </div>
          <div className="col-md-5 apps-items">
            <div className="row">
              {
                props.data.allWpTeamlanceSolution.edges[0].node.TeamlanceSolutionSingle.customApps.features
                &&props.data.allWpTeamlanceSolution.edges[0].node.TeamlanceSolutionSingle.customApps.features.length>0
                &&props.data.allWpTeamlanceSolution.edges[0].node.TeamlanceSolutionSingle.customApps.features.map((data,key)=>{
                    return(
                      <div key={key} className=" col-md-6">
                        <div className="apps-info">
                          <span dangerouslySetInnerHTML={{__html:data.icon}} style={{backgroundColor:data.iconBgColour}}></span>
                          <p>{data.title}</p>
                        </div>
                       </div>
                    )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="industries-sec">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <div className="about-heading mb-5">
              <p>{props.data.allWpTeamlanceSolution.edges[0].node.TeamlanceSolutionSingle.buildTogather.title}</p>
              <h1>{props.data.allWpTeamlanceSolution.edges[0].node.TeamlanceSolutionSingle.buildTogather.headline}</h1>
            </div>
            <div className="row">
              <div id="myaddCarouselservice" className="owl-carousel owl-theme myaddCarouselservice desktop-hidden">
                {props.data.allWpTeamlanceSolution.edges[0].node.TeamlanceSolutionSingle.buildTogather.buildFeatures
                &&props.data.allWpTeamlanceSolution.edges[0].node.TeamlanceSolutionSingle.buildTogather.buildFeatures.length>0
                &&props.data.allWpTeamlanceSolution.edges[0].node.TeamlanceSolutionSingle.buildTogather.buildFeatures.map((data,key)=>{
                    return(
                      <div key={key} className="item">  
                        <div className="ids-items">
                          <div dangerouslySetInnerHTML={{__html:data.iconSvg}} className="ids-icon"></div>
                          <p>{data.title}</p>
                        </div>
                       </div>
                    )
                  })
                }
              </div>
            </div>
            <div className="row mobile-hidden">
            {props.data.allWpTeamlanceSolution.edges[0].node.TeamlanceSolutionSingle.buildTogather.buildFeatures
                &&props.data.allWpTeamlanceSolution.edges[0].node.TeamlanceSolutionSingle.buildTogather.buildFeatures.length>0
                &&props.data.allWpTeamlanceSolution.edges[0].node.TeamlanceSolutionSingle.buildTogather.buildFeatures.map((data,key)=>{
                    return(
                      <div className="col-md-4">
                        <div className="ids-items">
                          <div dangerouslySetInnerHTML={{__html:data.iconSvg}} className="ids-icon"></div>
                          <p>{data.title}</p>
                        </div>
                      </div>
                    )
                })}
            </div>
          </div>
          <div className="col-md-5 d-flex justify-content-center workout-image">
            <img src={props.data.allWpTeamlanceSolution.edges[0].node.TeamlanceSolutionSingle.buildTogather.image.sourceUrl} />
          </div>
        </div>
      </div>
      <div className="why-triangle">
        <div>
          <svg width={489} height={212} viewBox="0 0 489 212" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M490 67.5004L366.13 17.6306L7.49989 116.5L490 141.5L490 67.5004Z" fill="#F4F8FF" />
          </svg>
        </div>
      </div>
      </section>
    </Layout>
    ) 
}

export const productQuery = graphql`
    query($slug: String!) {
      allWpTeamlanceSolution(filter: {slug: {eq: $slug}}) {
        edges {
          node {
            slug
            content
            title
            TeamlanceSolutionSingle {
              buildTogather {
                buildFeatures {
                  title
                  iconSvg
                  icon {
                    sourceUrl
                  }
                }
                headline
                image {
                  sourceUrl
                }
                title
              }
              customApps {
                description
                features {
                  title
                  titleColour
                  iconColour
                  iconBgColour
                  icon
                  imageIcon {
                    sourceUrl
                  }
                }
                subHeading
                title
              }
              mainHeader {
                description
                image {
                  sourceUrl
                }
                title
              }
              makingProcess {
                description
                processSteps {
                  title
                  description
                }
                subHeading
                title
              }
            }
          }
        }
      }
    }
   `;

export default Solution;
